
@keyframes arrow-pulse-down {
    0% {
        background-position-x: 0px;
    }
    50% { 
        opacity: 1;
    }
    75%{
        opacity: .25; 
    }
    100% {
        background-position-x: 15px;
        opacity: 0; 
    }
}
@keyframes pulse-img {
    0% {
        opacity: 1;
        transform: scale(1)
    }
    50% {
        transform: scale(1.04)
    }
    100%{
        transform: scale(1)
    }
}
@keyframes pulse-down {
    0% {
        margin-top: 0;
        opacity: 1;
    }
    50% { 
        margin-top: 30px;
    }
    100% {
        margin-top: 0px;
    }
}