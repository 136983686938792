@import '_variables.scss';
@import 'keyframes.scss';

*{
    font-family: 'Inter', sans-serif !important;
    a{
        text-decoration: none;
        outline: none;
        &:hover{
            text-decoration: none;
            color: $green;
        }
    }
    ul{
        list-style: none;
        li{
            list-style: none;
        }
    }
}
img{
    max-width: 100%;
}
section{
    margin-bottom: 150px;
    @media screen and (max-width: 1500px){
        margin-bottom: 100px;
        &:first-of-type{
            margin-bottom: 50px;
            margin-top: 80px;
        }  
    }
}
.mask-black{
    background-color: #00000078;
    position: fixed;
    width: 100%;
    height: 100%;
    left: -100%;
    transition: .2s ease-in-out;
    &.active{
        left: 0;
        z-index: 2;
        top: 0;
    }
}
body{
    background: $bghome;
    
    @media screen and (max-width: 1024px){
        background-color: #2a2929;
    }
}
.breadcrumbs{
    background: url(../img/bg-breadcrumbs.webp)  no-repeat;
    background-position: center center;
    background-size: cover;
    width: 100%;
    padding: 100px;
    @media screen and (max-width: 1440px){
        margin-top: 60px;
        padding: 40px;
    }
    .title-breadcrumb{
        color: #fff;
        font-size: 40px;
        @media screen and (max-width: 1440px){
            margin-top: 28px;
            font-size: 30px;
        }
        span{
            font-size: 16px;
            @media screen and (max-width: 1440px){
                font-size: 12px;
            }
        }
    }
}
header{
    .logo{
        max-width: 200px;
        margin: 15px 0px;
    }
    @media screen and (max-width: 1024px){
        background: #2a2929;
        position: fixed;
        display: block;
        z-index: 99;
        top: 0;
        width: 100%;
    }
    .container-fluid{
        padding: 60px 45px 0;
        &.home-page{
            padding: 0px 45px; 
            @media screen and (max-width: 1220px){
                padding: 40px 45px;
            }
        }
        &.contact{
            padding: 60px 45px;
        }
        @media screen and (max-width: 1440px){
            padding: 30px 30px;
        }
    }
    .box-right{
        float:right;
        a{
            display: inline-block;
            &:first-child{
                border: 3px solid $green;
                font-size: 16px;
                font-weight: 500;
                color: $green;
                padding: 16px 20px;
                border-radius: 10px;
                margin-right: 50px;
                @media screen and (max-width: 1440px){
                    margin-right: 75px;
                }
            }
            &:nth-child(2){
                position: fixed;
                z-index: 99;
                right: 30px;
                top: 75px;
                @media screen and (max-width: 1440px){
                    top: 45px;
                }
            }
        }
    }
    .menu-itens{
        background-color: #2a2929;
        position: fixed;
        z-index: 999;
        top: 0;
        transition: .5s ease-in-out;
        -webkit-transition: .5s ease-in-out;
        -moz-transition: .5s ease-in-out;
        padding: 25px 45px;
        max-height: 135px;
        border-bottom: 3px solid $green;
        @media screen and (max-width: 1220px){
            background-color: #fff;
            max-width: 15%;
            height: 100%;
            max-height: 100%;
            padding: 15px 15px;
            right: -100%;
            z-index: 100;
            .d-none-important{
                display:none!important
            }
        }
        
        @media screen and (max-width: 1024px){
            max-width: 25%;
        }
        @media screen and (max-width: 768px){
            max-width: 35%;
        }
        @media screen and (max-width: 768px){
            max-width: 40%;
        }
        @media screen and (max-width: 560px){
            max-width: 50%;
        }
        &.active{
            right: 0;
        }
        .menu-home{
            display: inline-block;
            float:right;
            @media screen and (max-width: 1220px){
               float: none;
               padding: 0;
            }
            li{
                display: inline-block;
                padding: 30px 20px;
                position: relative;
                @media screen and (max-width: 1220px){
                    display: block;
                    padding: 15px 10px;
                }
                a{
                    color: $text;
                    font-size: 16px;
                    -webkit-transition: .2s ease-in-out;
                    -moz-transition: .2s ease-in-out;
                }
                &:hover{
                    a{
                        color: $green;
                    }
                }
                .canal-menu{
                    color: $green;
                    font-weight: 700;
                    border: 2px solid $green;
                    border-radius: 10px;
                    padding: 15px 20px;
                    transition: .2s ease-in-out;
                    &:hover{
                        border-color: #fff;
                        color: #fff;
                    }
                }
                .dropdown-content{
                    background-color: #2D2B2BD4;
                    border: 1px solid #979797;
                    position: absolute;
                    padding: 15px 10px;
                    border-radius: 10px;
                    top: 70px;
                    opacity: 0;
                    width: 0px;
                    -webkit-transition: .2s ease-in-out;
                    -moz-transition: .2s ease-in-out;
                    @media screen and (max-width: 1220px){
                        top: 0;
                        opacity: 0;
                        right: 115px;
                        background-color: #2D2B2B;
                    }
                    a{
                        color: #fff;
                        border-bottom: 1px solid #707070;
                        padding: 10px 0;
                        display: block;
                        -webkit-transition: .2s ease-in-out;
                        -moz-transition: .2s ease-in-out;
                        @media screen and (max-width: 560px){
                            font-size: 10px;
                        }
                        &:last-child{
                            border-bottom: none;
                        }
                        &:hover{
                            color: $green;
                        }
                    }
                    &.active{
                        width: 245px;
                        opacity: 1;
                        @media screen and (max-width: 560px){
                            width: 175px;
                        }
                    }
                }
            }
            .close{
                display: inline-block;
                padding: 30px 10px;
                margin-left: 20px;
            
                @media screen and (max-width: 1220px){
                    position: absolute;
                    top: -30px;
                    right: 5px;
                    max-width: 30px;
                }
            }
        }
    }
}
.banner{
    margin-bottom: 60px;
    @media screen and (max-width: 1024px){
        display:none;
    }
}
.title-home{
    color: $green;
    font-size: 34px;
    font-weight: 500;
    margin-bottom: 90px;
    @media screen and (max-width: 1200px){
        display: block;
        text-align: left;
        font-size: 26px; 
        margin-top: 30px;
    }
}
.description-home{
    color: $text;
    font-weight: 400;
    line-height: 45px;
    font-size: 20px;
    margin-bottom: 50px;
    @media screen and (max-width: 1200px){
        display: block;
        text-align: left;
        font-size: 20px;
        line-height: 30px;
    }
}
.button-flat{
    color: $green;
    font-weight: 500;
    font-size: 20px;
    @media screen and (max-width: 1200px){
        display: block;
        text-align: left;
        font-size: 12px;
    }
    &:after{
        content: ' ';
        background-image: url(../img/right-arrow.webp);
        background-repeat: no-repeat;
        width: 45px;
        height: 25px;
        display: inline-block;
        margin-left: 20px;
        background-position-y: 5px;  
    }
    &:hover:after{
        animation: arrow-pulse-down 1.5s infinite; 
    }
}
#distribuidora{
    @media screen and (max-width: 1220px){
        margin-top: 160px;
    }
    .pulse{
        animation: pulse-down 3s infinite;
        transform: scale(1.7);
        right: -100px;
        position: absolute;
        @media screen and (max-width: 1440px){
            transform: none;
            right: 0;
            position: relative;
            margin: 0 auto;
            display: block;
        }
    }
}
#channels{
    .mtop{
        margin-top: 175px;
        @media screen and (max-width: 1500px){
            margin-top: 70px;
        }
    }
    .effect{
        animation: pulse-img 3s ;
        animation-iteration-count: 6;
        @media screen and (max-width: 1220px){
            display: block;
            margin: 0 auto;
            animation: none;
        }
    }
    .title-home{
        text-align: right;
        @media screen and (max-width: 1220px){
            display: block;
            text-align: center;
        }
    }
    .description-home{
        text-align: right;
        @media screen and (max-width: 1220px){
            display: block;
            text-align: center;
        }
    }
    .button-flat{
        text-align: right;
        float: right;
        @media screen and (max-width: 1220px){
            display: block;
            text-align: center;
        }
    }
}
#grow{
    .grow-ilustration{
        max-width: 400px;
        margin: 30px auto 70px;
        display: block; 
        @media screen and (max-width: 768px){
            max-width: 100%;
        }
    }
}
#trusted{
    padding: 90px 0; 
    margin: 0;
    background: url(../img/convenios.png) no-repeat 100%;
    background-color: #fff;
    background-size: contain;
    min-height: 700px;
    @media screen and (max-width: 1024px){
        background: #fff;
        background-image: none;
    }
    .button{
        border: 3px solid $green;
        font-size: 16px;
        font-weight: 500;
        color: $green;
        padding: 16px 20px;
        border-radius: 10px;
        margin-right: 50px;
        max-width: 270px;
        text-align: center;
        transition: .3s ease-in-out;
        margin-top: 170px;
        @media screen and (max-width: 1024px){
            margin: 0 auto;
        }
        &:hover{
            transform: scale(1.03);
            background-color: $green;
            color: #fff;
        }
    }
}
#cases{
    margin: 0px;
    padding: 90px 0;
    background-color: #F1F1F1;
    iframe{
        border-radius: 10px;
        @media screen and (max-width: 1024px){
        max-width: 100%;
        }
    }
    .box-white{
        padding: 80px 15px;
        background-color: #fff;
        border-radius: 10px;
        margin-top: 50px;
        font-size: 14px;
        font-weight: 400;
        max-width: 540px;
        max-height: 310px;
        width: 100%;
        height: 100%;
        color:$text;
        box-shadow: 0 0 20px 0 #0000003b;
    }
}
#categories{
    padding: 70px 0;
    margin: 0;
    background-color: #fff;
    @media screen and (max-width: 1220px){
        padding: 40px 0;
    }
            .arrows{
                position: absolute;
                bottom: -25px;
                right: 0;
                cursor: pointer;
                &.slick-prev{
                    right: 40px;
                }
            }
        .link{
            margin: 20px;
            max-width: 330px;
            @media screen and (max-width: 768px){
                margin: 20px 0;
            }
            .box-categories{
                max-width: 320px;
                background-color: #ffff;
                border-radius: 10px;
                box-shadow: 0px 5px 30px -15px rgba(0,0,0,.25);
                padding: 10px;
                @media screen and (max-width: 1220px){
                    margin: 0 auto;
                }
            img{
                display: block;
                margin: 40px auto;
            }
            .title-categorie{
                font-size: 26px;
                text-align: center;
                color: $green;
                margin-bottom: 40px;
            }
            .description-categorie{
                color: #979797;
                font-size: 12px;
                line-height: 30px;
                font-weight: 400;
                padding: 15px;
                height: 180px;
                @media screen and (max-width: 1220px){
                    height: auto;
                }
            }
        }
    }
    .slider{
        padding: 0!important;
        text-align: center;
    }
}
#institutional{
    background-color: #fff;
    margin: 0;
    .box-mvv{
        margin-top: 60px;
        .title-mvv{
            text-align: center;
            color: $green; 
        }
        .description-mvv{
            color: $text;
            font-size: 14px;
            line-height: 25px;
            text-align: center;
        }
    }
    .institutional{
        padding: 140px 0;
        iframe{
            border-radius: 10px;
            @media screen and (max-width: 1024px){
                max-width: 100%;
            }
        }
        .box-white{
            padding: 40px 15px;
            background-color: #fff;
            border-radius: 10px;
            margin-top: 80px;
            font-size: 14px;
            font-weight: 400;
            max-width: 510px;
            margin-left: 30px;
            width: 100%;
            color:$text;
            box-shadow: 0 0 20px 0 #0000003b;
            @media screen and (max-width: 1024px){
                max-width: 100%;
                margin: 30px auto;
            }
        }
    }
    .nav-tabs{
        border: none;
        margin-bottom: 40px;
    }
    .nav-item{
        border: none;
        .nav-link{
            color: $text;
            font-size: 24px;
            text-align: center;
            padding: 20px 0;
            border: none;
            cursor: pointer;
            &.active{
                color: $green;
                border-bottom: 2px solid;
            }
            &:hover{
                color: $green;
            }
        }
    }
    .tab-content{
        .certified{
            margin: 40px auto 0;
            display: block;
            @media screen and (max-width: 1024px){
                margin: 0px auto 40px;
            }
        }
        .description-mps{
            color: $text;
            font-size: 18px;
            font-weight: 500;
            &.first{
                margin: 200px 0 50px;
                @media screen and (max-width: 1024px){
                    margin: 0px auto;
                }
            }
        }
        .contracts-description{
            text-align: center;
            color: $text;
            font-size: 24px;
            font-weight: 500;
            margin-bottom: 50px;
        }
        .pdf-icon{
            max-width: 200px;
            margin: 0 auto;
            display: block;
        }
        .title-pdfs{
            color: $text;
            font-size: 20px;
            font-weight: 500;
            text-align: center;
            margin-top: 20px;
            margin-bottom: 70px;
        }
    }
}
#contact{
    margin-bottom: 0;
    background: url('../img/map.webp') no-repeat;
    background-position-x: 1300px;
    .breadcrumbs{
        background: transparent;
        padding: 0;
        margin-bottom: 60px;
    }
    .contact-form{
        input{
            margin-bottom: 40px;
            width: 100%;
            color: #fff;
            background-color: transparent;
            border: none;
            border-bottom: 2px solid #fff;
            &::placeholder{
                color: #fff;
                opacity: 1;
            }
            &:focus{
                color: $green;
                border-bottom-color: $green;
                &::placeholder{
                    color: $green;
                }
            }
        }
        textarea{
            margin-bottom: 40px;
            width: 100%;
            color: #fff;
            background-color: transparent;
            border: none;
            max-height: 100px;
            &::placeholder{
                color: #fff;
                opacity: 1;
            }
        }
        button{
            width: 100%;
            background-color:  #fff;
            border-radius: 10px;
            border: none;
            margin-bottom: 100px;
            cursor: pointer;
            padding: 20px 0;
        }
    }
    .nav-tabs{
        border: none;
    }
    .nav-item{
        border: none;
        .nav-link{
            color: $text;
            font-size: 16px;
            text-align: center;
            padding: 60px 0;
            border: none;
            cursor: pointer;
            &.active{
                color: $green;
                border-bottom: 2px solid;
                height: 100%;
            }
            &:hover{
                color: $green;
            }
        }
    }
    iframe{
        width: 100%;
        height: 430px;
    }
    .tabs-bg{
        background-color: #fff;
        margin-top: 300px;
    }
    .tab-content{
        .box-infos{
            padding: 60px 0 30px;
            div{
                margin-bottom: 40px;
                a{
                    color: #fff;
                }
                img{
                    margin-right: 20px;
                }
            }
        }
    }
}
#soluctions{
    margin-bottom: 0;
    padding: 0 0 60px;
    background-color: #fff;
    .second{
        @media screen and (max-width: 1024px){
            float:right;
        }
    }
    .soluction-img{
        margin-top: 120px;
    }
    .soluction-description{
        color: $text;
        font-size: 16px;
        padding: 50px 0 0;
        margin-top: 150px;
    }
}
footer{
    padding: 30px 0 10px;
    background-color: #2D2B2B;
    .social-icons{
        margin: 15px 25px 60px;
        display: inline-block;
        transition: .2s ease-in-out;
        &:first-child{
            margin-left: 0;
        }
        @media screen and (max-width: 1024px){
            margin: 15px 15px 20px;
        }
        &:hover{
            filter: brightness(1.5);
            transform: scale(1.04);
        }
    }
    .copyright{
        font-size: 12px;
        color: #fff;
        margin-top: 40px;
        span{
            color: $green;
        }
    }
    .menu-institutional{
        .title-footer{
            margin-bottom: 30px;
            padding: 20px 0 0;
            color: #fff;
            font-weight: 500;
            font-size: 24px;
        }
        .menu-footer{
            margin: 0;
            padding:0;
            a   {
                color: #fff;
                margin: 0px 0 20px;
                display: inline-block;
                &:before{
                    content: "";
                    display: inline-block;
                    height: 20px;
                    width: 2px;
                    background-color: #93c03e;
                    margin-right: 10px;
                    vertical-align: middle;
                    position: absolute;
                    left: 8px;
                    margin: 2px 0;
                    opacity: 0;
                    transition: .2s ease-in-out;
                }
                &:after{
                    content: "";
                    display: block;
                    height: 2px;
                    width: 0;
                    background-color: #93c03e;
                    margin-left: 10px;
                    bottom: 0;
                    margin: 2px 0;
                    transition: .2s ease-in-out;
                }
                &:hover{
                    &:before{
                        opacity: 1;
                    }
                    &:after{
                        width: 100%;
                    }
                }
            }
        }
    }
}
#segmentos{
    background-color: #fff;
    margin: 0;
    .box-categories{
        max-width: 320px;
        background-color: #ffff;
        border-radius: 10px;
        padding: 0 0 20px;
        box-shadow: 0px 5px 30px -15px rgba(0,0,0,.25);
        margin-bottom: 30px;
        display: block;
        @media screen and (max-width: 1220px){
            margin: 0 auto;
        }
        img{
            display: block;
            margin: 40px auto;
            border-top-right-radius: 10px;
            border-top-left-radius: 10px;
            width: 320px;
            height: 220px;
            object-fit: contain;
        }
        .title-categorie{
            font-size: 24px;
            text-align: center;
            color: $green;
            margin-bottom: 20px;
        }
        .categorie-description{
            color: #979797;
            font-size: 12px;
            line-height: 20px;
            font-weight: 400;
            padding: 10px; 
            overflow: hidden;
            height: 140px;
        }
        .button{
            color: $text;
            border: 2px solid $green;
            border-radius: 10px;
            text-align: center;
            display: inline-block;
            padding: 10px 40px;
            margin: 0 auto;            
            display: block;
            max-width: 170px;
            font-weight: 500;
            font-size: 12px;
            transition: .2s ease-in-out;
            &:hover{
                transform: scale(1.05);
                color: $green;
            }
        }
    }
}

.parsley-errors-list .parsley-required{
    color: red;
    border-color: red;
    margin: 0;
    font-size: 12px;
    width: 100%;
    float: right;
    text-align: right;
}
.parsley-error{
    color: red;
    border-color: red;
    margin: 0 !important;
} 